import React, { useState, useEffect, useReducer } from "react";

import Press from "./Press";
import config from "./config/config";

const INTERVAL_TIME = 500;
const API = config.api;

const INITIAL_STATE = {
  position: "UP",
  continuous: false,
  power: true,
  isError: false,
  stopMe: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_POSITION":
      return { ...state, position: action.value };
    case "SET_CONTINUOUS":
      return { ...state, continuous: action.value };
    case "SET_POWER":
      return { ...state, power: action.value };
    case "SET_ERROR":
      return { ...state, isError: action.value };
    case "SET_STOP":
      return { ...state, stopMe: action.value };
    default:
      throw new Error();
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [machines, setMachines] = useState([]);
  const [machineId, setMachineId] = useState();
  const [iiotDevices, setIiotDevices] = useState([]);
  const [iiotDeviceId, setIiotDeviceId] = useState();

  // Machines
  useEffect(() => {
    async function fetchMachines() {
      try {
        const response = await fetch(`${API}/api/v1/simulator/machines`);
        let machines = await response.json();

        // Get just machines with variables
        machines = machines.filter((machine) => machine.variables?.length > 0);
        const [machine] = machines;
        setMachines(machines);
        setMachineId(machine?._id);
      } catch (error) {
        console.error(error);
      }
    }

    fetchMachines();
  }, []);
  // Iiot devices
  useEffect(() => {
    async function fetchIiotDevices() {
      try {
        const response = await fetch(`${API}/api/v1/simulator/iiot-devices`);
        let iiotDevices = await response.json();
        // Get just iiot devices with variables
        iiotDevices = iiotDevices.filter(
          (iiotDevice) => iiotDevice.variables?.length > 0
        );
        const [iiotDevice] = iiotDevices;
        setIiotDevices(iiotDevices);
        setIiotDeviceId(iiotDevice?._id);
      } catch (error) {
        console.error(error);
      }
    }
    fetchIiotDevices();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.continuous) {
        dispatch({
          type: "SET_POSITION",
          value: state.position === "UP" ? "DOWN" : "UP",
        });
        // Add cycle if position is down
        if (state.position === "DOWN") {
          async function addCycle() {
            const response = await fetch(`${API}/api/v1/simulator/add-cycle`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ machineId }),
            });
            const result = await response.json();
            setNodeValue(result.nodeId, result.value);
          }
          addCycle();
          if (state.stopMe) {
            dispatch({ type: "SET_STOP", value: false });
            dispatch({ type: "SET_CONTINUOUS", value: false });
          }
        }
      }
    }, INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [state.continuous, state.position, state.stopMe, machineId, state]);

  function setNodeValue(nodeId, value) {
    const control = document.getElementById(nodeId);
    control.textContent = value;
  }

  async function power() {
    // Toggle power
    state.power = !state.power;
    const response = await fetch(`${API}/api/v1/simulator/power`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId, state: state.power }),
    });
    const result = await response.json();
    setNodeValue(result.resultOn.nodeId, result.resultOn.value);
    setNodeValue(result.resultStatus.nodeId, result.resultStatus.value);
    dispatch({ type: "SET_POWER", value: state.power });
    // Stop machine if power is off
    if (!state.power) {
      dispatch({ type: "SET_STOP", value: false });
      dispatch({ type: "SET_CONTINUOUS", value: false });
    }
  }

  async function start() {
    const response = await fetch(`${API}/api/v1/simulator/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId }),
    });
    const result = await response.json();
    setNodeValue(result.nodeId, result.value);
    dispatch({ type: "SET_CONTINUOUS", value: true });
  }

  async function stop() {
    const response = await fetch(`${API}/api/v1/simulator/stop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId }),
    });
    const result = await response.json();
    setNodeValue(result.nodeId, result.value);
    dispatch({ type: "SET_STOP", value: true });
  }

  async function reset() {
    const response = await fetch(`${API}/api/v1/simulator/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId }),
    });
    const result = await response.json();
    setNodeValue(result.nodeId, result.value);
  }

  async function error() {
    await stop();
    const response = await fetch(`${API}/api/v1/simulator/error`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ machineId, isError: !state.isError }),
    });
    const result = await response.json();
    setNodeValue(result.nodeId, result.value);
    dispatch({ type: "SET_ERROR", value: !state.isError });
    // Stop machine if error is on
    if (state.isError) {
      dispatch({ type: "SET_STOP", value: false });
      dispatch({ type: "SET_CONTINUOUS", value: false });
    }
  }

  const iiotDevice = iiotDevices.find(
    (iiotDevice) => iiotDevice._id === iiotDeviceId
  );

  const machine = machines.find((machine) => machine._id === machineId);

  async function generate() {
    const response = await fetch(`${API}/api/v1/simulator/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ iiotDeviceId }),
    });
    const result = await response.json();
    // Update control with id=result.nodeId
    let { nodeId, value } = result;
    const control = document.getElementById(nodeId);
    // Round value to 3 decimals if it is a number
    if (typeof value === "number") {
      value = value.toFixed(3);
    }
    const { data } = JSON.parse(value);
    if (data) {
      const dataVars = Object.keys(data).map((key) => {
        const variable = iiotDevice.variables.find((v) => v.type === key);
        return {
          nodeId: variable.nodeId,
          value: data[key],
        };
      });
      dataVars.forEach(({ nodeId, value }) => {
        const control = document.getElementById(nodeId);
        control.textContent = value;
      });
    } else {
      control.textContent = value ?? "";
    }
  }

  return (
    <div>
      <div className="container">
        <div className="columns">
          {/* MACHINES */}
          <div className="column is-3">
            <div className="field">
              <div className="control">
                <h1
                  className="title is-2"
                  style={{
                    marginTop: 50,
                    color: "white",
                  }}
                >
                  Machines
                </h1>
                <div className="select is-large" style={{ width: "24rem" }}>
                  <select
                    value={machineId}
                    onChange={(event) => setMachineId(event.target.value)}
                    style={{ width: "100%" }}
                  >
                    {machines.map(({ _id, name }) => (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* VARIABLES */}
                <div
                  className="box is-large"
                  style={{ width: "24rem", marginTop: "2rem" }}
                >
                  <h4 className="title is-3">Variables</h4>
                  {machine?.variables.map(
                    ({ type, nodeId, value, dataType }) => (
                      <div key={nodeId} className="columns">
                        <div className="column">
                          <p
                            className="subtitle is-4"
                            style={{
                              marginBottom: 1,
                            }}
                          >
                            {type}
                          </p>
                          <p className="subtitle is-6">{nodeId}</p>
                        </div>
                        <div className="column is-narrow">
                          <p
                            id={nodeId}
                            className="subtitle is-4"
                            style={{
                              marginBottom: 1,
                            }}
                          >
                            {value}
                          </p>
                          <p className="subtitle is-6">{dataType}</p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* PRESS */}
          <div className="column is-6 has-text-centered">
            <Press power={state.power} position={state.position} />
          </div>
          {/* IIOT DEVICES */}
          <div className="column is-3">
            <div className="field">
              <div className="control">
                {/* TITLE */}
                <h1
                  className="title is-2"
                  style={{
                    marginTop: 50,
                    color: "white",
                  }}
                >
                  Iiot Devices
                </h1>
                {/* SELECT */}
                <div className="select is-large" style={{ width: "32rem" }}>
                  <select
                    value={iiotDeviceId}
                    onChange={(event) => setIiotDeviceId(event.target.value)}
                    style={{ width: "100%" }}
                  >
                    {iiotDevices.map(({ _id, name }) => (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* VARIABLES */}
                <div
                  className="box is-large"
                  style={{ width: "32rem", marginTop: "2rem" }}
                >
                  <h4 className="title is-3">Variables</h4>
                  {iiotDevice?.variables
                    ?.filter((p) => p.isVisible)
                    .map(({ name, value, unit, nodeId }) => (
                      <div key={name} className="columns">
                        <div className="column">
                          <p className="subtitle is-4">{name}</p>
                        </div>
                        <div className="column is-narrow">
                          <p className="subtitle is-4" id={nodeId}>
                            {value}
                          </p>
                        </div>
                        <div className="column is-narrow">
                          <p className="subtitle is-4">{unit}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#2B3B4E", padding: "2.4rem 0 3rem" }}>
        <div className="container">
          <div className="columns">
            {/* BUTTON-ON-OFF */}
            <div className="column">
              <button
                className="button is-large is-fullwidth"
                onClick={power}
                type="button"
                aria-hidden
                tabIndex={0}
              >
                {state.power ? "OFF" : "ON"}
              </button>
            </div>
            {/* BUTTON-RESET */}
            <div className="column">
              <button
                className="button is-large is-fullwidth"
                onClick={reset}
                type="button"
                aria-hidden
                tabIndex={-1}
              >
                Reset
              </button>
            </div>
            {/* BUTTON-ERROR */}
            <div className="column">
              <button
                className="button is-large is-danger is-fullwidth"
                disabled={!state.power}
                onClick={error}
                type="button"
                aria-hidden
                tabIndex={0}
              >
                {!state.isError ? "Error" : "Fix error"}
              </button>
            </div>
            {/* BUTTON-START-STOP */}
            <div className="column">
              {state.continuous ? (
                <button
                  className="button is-interrupt is-large is-fullwidth"
                  onClick={stop}
                  disabled={!state.power || state.stopMe}
                  type="button"
                  aria-hidden
                  tabIndex={0}
                >
                  Stop
                </button>
              ) : (
                <button
                  className="button is-production is-large is-fullwidth"
                  onClick={start}
                  disabled={!state.power || state.isError}
                  type="button"
                  aria-hidden
                  tabIndex={0}
                >
                  Continuous
                </button>
              )}
            </div>
            {/* BUTTON-GENERATE */}
            <div className="column">
              <button
                className="button is-large is-warning is-fullwidth"
                disabled={!iiotDevice}
                type="button"
                aria-hidden
                tabIndex={0}
                onClick={generate}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
